<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>Determine whether each of the following statements are True or False.</p>
      <v-divider />
      <div v-for="(prompt, i) in prompts" :key="prompt" class="mt-5">
        <stemble-latex :content="prompt" />
        <v-radio-group v-model="inputs[`input${i + 1}`]" :disabled="!allowEditing" row hide-details>
          <v-radio label="True" value="true" />
          <v-radio label="False" value="false" />
        </v-radio-group>
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question200e',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
      },
      prompts: [
        'Atomic radius decreases down the periodic table in a group',
        'Electronegativity increases down the periodic table',
        'Anions are larger in size than their parent atoms',
        'Anions are formed by the loss of electrons from parent atoms',
        '$\\text{m}_l$ determines the shape of an orbital',
        'Two electrons in the same orbital must have the same spin',
      ],
    };
  },
};
</script>
